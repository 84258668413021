import React from 'react';
import EventPoster from './components/EventPoster';

function App() {
  return (
    <div className="App">
      <EventPoster />
    </div>
  );
}

export default App;