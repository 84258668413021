import React from 'react';
import { Calendar, MapPin, Info } from 'lucide-react';

const EventPoster = () => {
  const eventDetails = {
    name: "Come & See",
    date: "20 Aug to 2 Sep",
    location: "台灣中央西路二段124號中壢區桃園市320",
    description: "To know what's the life of sister",
    institution: "SSVM"
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-600 to-blue-500 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-2xl overflow-hidden max-w-4xl w-full">
        <div className="p-8">
          <h1 className="text-5xl font-bold text-center mb-6 text-teal-700">{eventDetails.name}</h1>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-6">
            <div className="flex items-center text-gray-600">
              <Calendar className="mr-2" />
              <span>{eventDetails.date}</span>
            </div>
            <div className="flex items-center text-gray-600">
              <MapPin className="mr-2" />
              <span>{eventDetails.location}</span>
            </div>
          </div>
          <p className="text-xl text-center mb-8 text-gray-700">{eventDetails.description}</p>
          <div className="bg-teal-100 rounded-lg p-6 mb-8">
            <div className="text-center">
              <h2 className="text-2xl font-semibold mb-2 text-teal-700">Institution</h2>
              <p className="text-gray-700 text-3xl font-bold">{eventDetails.institution}</p>
            </div>
          </div>
          <div className="text-center">
            <button className="bg-teal-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-teal-700 transition duration-300">
              Register Now
            </button>
          </div>
        </div>
        <div className="bg-teal-700 text-white py-4 px-8 flex justify-between items-center">
          <div className="flex items-center">
            <Info className="mr-2" />
            <span>Experience the life of a sister</span>
          </div>
          <button onClick={() => alert('More information coming soon!')} className="underline hover:text-teal-200">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventPoster;